import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeApp } from "firebase/app";
import './assets/scss/main.scss'

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const config = {
  apiKey: 'AIzaSyAakf2r-mvj9R-uTIY0VyKuxH585z8KzUI',
  authDomain: 'bon-air-bf027.firebaseapp.com',
  projectId: 'bon-air-bf027',
  storageBucket: 'bon-air-bf027.appspot.com',
  messagingSenderId: '865037092110',
  appId: '1:865037092110:web:ffd93c8396dfc6fca3835f',
  measurementId: 'G-VPPZE0ZS45'
};
const app = initializeApp(config);

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
