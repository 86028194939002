import './App.scss';
import React from 'react';
import welcomeImg from './assets/images/welcome.jpg';
import logoBonair from './assets/images/logo-bonair.png';
import logo25 from './assets/images/logo25.svg';

const App: React.FC = () => {
  const pdfFR = "https://firebasestorage.googleapis.com/v0/b/bon-air-bf027.appspot.com/o/Bon%20Air%202024%20-%20FR.pdf?alt=media&token=e8c27b6b-6ca3-4d86-8264-0f751353f93e";
  const pdfNL = "https://firebasestorage.googleapis.com/v0/b/bon-air-bf027.appspot.com/o/Bon%20Air%202024%20-%20NL.pdf?alt=media&token=7439bad6-a8a9-4abe-992d-675b4a638c0b"


  return (
    <div className='home' style={{ backgroundImage: `url(${welcomeImg}) ` }}>
      <div className='home--content col txt-center text-white w-full p-rel'>
        <div className='home--content-img'>
          <img className='m-x-32 m-y-16' src={logoBonair} alt="" />
          <img className='m-x-32 m-y-16' src={logo25} alt="" />
        </div>
        <div className='home--content-text justify-center'>
          <div className='col-md-6 p-2'>
            <p>Geachte Heer, Mevrouw, Beste Apotheker,</p>
            <p>In de bijlage vindt u belangrijke informatie betreffende het Bon Air Seminarie 2024.</p>
            <p>We blijven te uwer beschikking voor verdere vragen.</p>
            <p>Met vriendelijke groeten,</p>
            <p><b>Daniel DE RON - Bénédicte GIROUL</b></p>
            <p>Pharmaciens-Apothekers</p>
            <div className='home--content-button block p-t-12'>
              <a href={pdfNL} target="_blank" className="button-link">
                <button className='p-y-8 m-x-12 m-y-4'>NL Versie</button>
              </a>
            </div>
          </div>
          <div className='col-md-6 p-2'>
            <p>Chère Madame, Monsieur, cher Pharmacien,</p>
            <p>Vous trouverez en annexe des informations importantes relatives au Séminaire du Bon Air 2024.</p>
            <p>Nous sommes à votre disposition pour toutes questions.</p>
            <p>Bien à vous,</p>
            <p><b>Daniel DE RON - Bénédicte GIROUL</b></p>
            <p>Pharmaciens-Apothekers</p>
            <div className='home--content-button block p-t-12'>
              <a href={pdfFR} target="_blank" className="button-link">
                <button className='p-y-8 m-x-12 m-y-4'>Version FR</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

